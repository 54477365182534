import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`1) Using your computer, open the menu by clicking on the user icon at the top
right and login with your private account and then select "Management portal".`}</p>
    <br />
or
    <br />
    <br />
2) Open https://trailguide.net/portal in your web browser and follow the
login instructions.
    <br />
    <br />
    <div className="flex justify-around">
  <Image src="pro/login.png" className="w-2/5" mdxType="Image" />
  <Image src="pro/click-management-portal.png" className="w-2/5" mdxType="Image" />
    </div>
    <br />
    <br />
    <Link className="float-left" to="/pro" mdxType="Link">
  ← Trailguide professional
    </Link>
    <Link className="float-right" to="/pro/upload" mdxType="Link">
  How to upload an activity →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      